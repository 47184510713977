import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SiteForm = _resolveComponent("SiteForm")

  return (_ctx.profile)
    ? (_openBlock(), _createBlock(_component_SiteForm, {
        key: 0,
        editModel: _ctx.profile
      }, null, 8, ["editModel"]))
    : _createCommentVNode("", true)
}