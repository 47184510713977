import { axiosInstance as axios } from "./interceptor";
// import axios from "axios";
import { getURLFunc } from "./apiUtils";

export interface CountryItem {
  code: string;
  name: string;
}

const getUrl = getURLFunc("/study/api/countries");

export function getCountriesForFilter() {
  return axios.get<CountryItem[]>(getUrl("")).then((r) => r.data);
}

export function getCountryByCode(countryCode: string) {
  return axios.get<CountryItem>(getUrl(`/${countryCode}`)).then((r) => r.data);
}
