import useUserRole, { UserRole } from "@/uses/useUserRoles";
import { createSite, editSite } from "@/api/SitesApi";
import {
  COUNTRY_REQUIRED_MESSAGE,
  DATA_STORAGE_REQUIRED_MESSAGE,
  SITE_NAME_REQUIRED_MESSAGE,
  WEBSITE_FORMAT_MESSAGE,
} from "./../../../utils/validationMessages";
import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  watch,
  nextTick,
} from "vue";

import { FilterObject } from "@/components/DropdownFilter/DropdownFilter";

import DropdownFilter from "@/components/DropdownFilter/DropdownFilter.vue";
import LeavePreventPopup from "@/components/LeavePreventPopup/LeavePreventPopup.vue";

import router from "@/router";

import { useStore } from "@/store/store";
import useFormUtils from "@/uses/useFormUtils";
import { SiteProfile } from "@/api/interfaces/SitesInterfaces";
import { getCountriesFilterOptions } from "@/utils/filtersUtils";
import { showError, showErrorOptional } from "@/utils/notificationUtils";
import {
  DataStorageItem,
  getDataStoragesByCountryCode,
} from "@/api/DataStoragesApi";
import { CountryItem, getCountriesForFilter } from "@/api/CountriesApi";
import { siteCountryCanBeEdited } from "@/api/validationsApi";

export default defineComponent({
  name: "SiteForm",
  props: {
    editModel: { default: null, type: Object as () => SiteProfile | null },
  },
  components: { DropdownFilter, LeavePreventPopup },
  setup(props) {
    const store = useStore();
    const useRoles = useUserRole(store);

    const isEdit = computed(() => props.editModel);

    const form = reactive({
      name: "",
      country_code: "",
      data_storage_id: "",
      comment: "",
      address_line1: "",
      address_line2: "",
      city: "",
      region: "",
      zip: "",
      web_site: "",
      // primary_contact_person: {},
      ...props.editModel,
      // data_storage: 1,
    });

    // const canEditCountry = ref<boolean>(true);
    // if (isEdit.value && props.editModel?.id) {
    //   siteCountryCanBeEdited(props.editModel?.id).then((result) => {
    //     if (result) {
    //       canEditCountry.value = result.is_valid;
    //     }
    //   });
    // }
    
    const canEditCountry = ref<boolean>(isEdit.value ? false : true);

    const rules = reactive({
      name: [
        {
          required: true,
          message: SITE_NAME_REQUIRED_MESSAGE,
          trigger: "blur",
        },
      ],
      country_code: [
        {
          required: true,
          message: COUNTRY_REQUIRED_MESSAGE,
          trigger: "blur",
        },
      ],
      data_storage_id: [
        {
          required: true,
          message: DATA_STORAGE_REQUIRED_MESSAGE,
          trigger: "blur",
        },
      ],

      web_site: [
        {
          message: WEBSITE_FORMAT_MESSAGE,
          trigger: "blur",
          validator: (rule: any, value: string) => {
            if (value) {
              return /^(https?:\/\/)(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)?$/.test(
                value
              );
            } else {
              return true;
            }
          },
        },
      ],
      //TODO add validation rules for address and website
    });

    const state = reactive({
      isSubmitted: false,
      loading: false,
      countries: {
        items: [],
        values: "" || form.country_code,
      } as FilterObject,
      dataStorages: {
        items: [],
        values: "" || form.data_storage_id,
      } as FilterObject,
    });

    const dataStoragesList = ref<DataStorageItem[]>([]);
    watch(state.countries, (newValue: any, oldValue: any) => {
      const isFirst = isEdit.value && dataStoragesList.value.length === 0;
      if (form.country_code != newValue.values || isFirst) {
        form.country_code = newValue.values;
        nextTick(() => {
          getDataStoragesByCountryCode(form.country_code).then(
            (dataStorages) => {
              dataStoragesList.value = dataStorages;
              if (dataStorages.length === 1 && !isFirst) {
                form.data_storage_id = dataStorages[0].id;
                state.dataStorages.values = dataStorages[0].id;
              }

              state.dataStorages.items = [
                {
                  header: "",
                  options: dataStorages.map((c) => ({
                    text: c.name,
                    value: c.id,
                  })),
                },
              ];
            }
          );
        });
      }
    });

    watch(state.dataStorages, (newValue: any, oldValue: any) => {
      if (form.data_storage_id != newValue.values) {
        form.data_storage_id = newValue.values;
      }
    });

    const countriesList = ref<CountryItem[]>([]);
    onBeforeMount(() => {
      if (useRoles.hasRole(UserRole.SystemAdmin)) {
        getCountriesForFilter().then((countries) => {
          countriesList.value = countries;
          state.countries.items = [
            { header: "", options: getCountriesFilterOptions(countries) },
          ];
        });
      }
    });

    const currentCountry = computed(() => {
      if (countriesList.value.length) {
        const country = countriesList.value.find(
          (c) => c.code === form.country_code
        );
        if (country) {
          return country.name;
        }
      }

      return null;
    });

    const siteForm = ref<any>(null);
    return {
      state,
      form,
      rules,
      isEdit,
      canEditCountry,
      siteForm,
      ...useRoles,
      ...useFormUtils(form),
      isRequiredFilled: computed(
        () => !!(form.name && form.country_code && form.data_storage_id)
      ),
      currentCountry,
      currentDataStorage: computed(() => {
        if (dataStoragesList.value.length) {
          const storage = dataStoragesList.value.find(
            (c) => c.id == form.data_storage_id
          );
          if (storage) {
            return storage.name;
          }
        }

        return "";
      }),
      submitForm() {
        state.loading = true;
        siteForm.value.validate(async (valid: boolean) => {
          if (valid) {
            const model = {
              ...form,
            };

            try {
              if (isEdit.value) {
                await editSite(model as SiteProfile);

                setTimeout(() => router.back(), 100);
              } else {
                const siteId = await createSite(model as SiteProfile);

                if (store.state.meta.prevRouteName === "Sites") {
                  setTimeout(
                    () =>
                      router.push({
                        name: "View Site",
                        params: { id: siteId },
                      }),
                    100
                  );
                } else {
                  setTimeout(() => router.back(), 100);
                }
              }

              state.isSubmitted = true;
            } catch (ex) {
              console.log(ex);
              showErrorOptional(ex.title, ex);
            } finally {
              state.loading = false;
            }
          }
          state.loading = false;
        });
      },
      cancel() {
        router.back();
      },
      blurWebsite() {
        if (form.web_site) {
          if (!form.web_site.startsWith("http")) {
            form.web_site = "http://" + form.web_site;
          }
        }
        form.web_site.trim();
      },
    };
  },
});
