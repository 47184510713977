import { axiosInstance as axios } from "./interceptor";
// import axios from "axios";
import { getURLFunc } from "./apiUtils";

export interface DataStorageItem {
  id: string;
  name: string;
}

const getUrl = getURLFunc("/study/api/data-storages");

export function getDataStorageById(dataStorageId: number) {
  return axios
    .get<DataStorageItem>(getUrl(`/${dataStorageId}`))
    .then((r) => r.data);
}

export function getDataStoragesByCountryCode(countryCode: string) {
  return axios
    .get<DataStorageItem[]>(getUrl(`/by-country-code/${countryCode}`))
    .then((r) => r.data);
}
