import { SiteProfile } from "@/api/interfaces/SitesInterfaces";
import { MetadataMutations } from "../../../store/metadataModule";
import { defineComponent, onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";

import { useStore } from "@/store/store";

import SiteForm from "./../SiteForm/SiteForm.vue";
import { viewSiteProfile } from "@/api/SitesApi";

export default defineComponent({
  name: "EditSite",
  props: {},
  components: { SiteForm },
  setup() {
    const route = useRoute();
    const store = useStore();

    const profile = ref<SiteProfile>();

    onBeforeMount(() => {
      viewSiteProfile(route.params.id as string).then((siteProfile) => {
        profile.value = siteProfile;
        store.commit(
          MetadataMutations.SET_BREAD_CRUMBS_NAME,
          profile.value.name
        );
      });
    });

    return {
      profile,
    };
  },
});
